import { generateLicense, LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';

import App from './App';
import './index.css';

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: '1',
    licensingModel: 'subscription',
    scope: 'premium',
  })
);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
