export enum PlansQueryKey {
  Uuid = 'UUID',
  PlanId = 'PLAN_ID',
  PlanName = 'PLAN_NAME',
  GroupUuid = 'GROUP_UUID',
  CreatedAt = 'CREATED_AT',
  MonthlyFee = 'MONTHLY_FEE',
  NumberOfShops = 'NUMBER_OF_SHOPS',
  AdditionalUserFee = 'ADDITIONAL_USER_FEE',
  AdditionalShopFee = 'ADDITIONAL_SHOP_FEE',
  AdditionalSiteFee = 'ADDITIONAL_SITE_FEE',
  NumberOfAdminUsers = 'NUMBER_OF_ADMIN_USERS',
  AdditionalMemberFee = 'ADDITIONAL_MEMBER_FEE',
  NumberOfMemberSites = 'NUMBER_OF_MEMBER_SITES',
  AvailableForSubscribe = 'AVAILABLE_FOR_SUBSCRIBE',
  NumberOfMembersPerSite = 'NUMBER_OF_MEMBERS_PER_SITE',
  NumberOfMembersPerLicense = 'NUMBER_OF_MEMBERS_PER_LICENSE',
}

export enum RevenueShareQueryKey {
  To = 'TO',
  Fee = 'FEE',
  From = 'FROM',
  Unit = 'UNIT',
  CreatedAt = 'CREATED_AT',
}
