import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import AddNetworkDialog from '~/components/dialog/add-supported-networks';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import ListTable from '~/components/list-table';
import GridToolbarSearchByAPI from '~/components/list-table/GridToolbarSearchByAPI';
import WrapperWithFab from '~/components/WrapperWithFab';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { AdminRole, ListNetworksDocument, useListNetworksQuery, useRemoveNetworkMutation } from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';
import { StyledComponentProps } from '~/types/material-ui';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTable: {
    '.image-list-thumbnail': {
      height: '64px',
      width: '64px',
      borderRadius: '4px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

export enum NetworkQueryKey {
  Uuid = 'UUID',
  ChainId = 'CHAIN_ID',
  Name = 'NAME',
  Icon = 'ICON',
  RpcUrl = 'RPC_URL',
  TokenName = 'TOKEN_NAME',
  TokenSymbol = 'TOKEN_SYMBOL',
  TokenSymbols = 'TOKEN_SYMBOLS',
  BlockExplorer = 'BLOCK_EXPLORER',
  ContractAddress = 'CONTRACT_ADDRESS',
  WalletAddress = 'WALLET_ADDRESS',
  SubgraphUrl = 'SUBGRAPH_URL',
  TestMode = 'TEST_MODE',
  CreatedAt = 'CREATED_AT',
}

export interface INetworksList {
  id: string;
  [NetworkQueryKey.ChainId]: string;
  [NetworkQueryKey.Name]: AdminRole;
  [NetworkQueryKey.CreatedAt]: Date;
  [NetworkQueryKey.TestMode]: string;
  [NetworkQueryKey.SubgraphUrl]: string;
  [NetworkQueryKey.TokenSymbol]: string;
}

const initQuery = {
  page: 1,
  searchText: '',
  limit: ITEMS_PER_PAGE,
  orderBy: verifyOrderKey(getLocalStorage('networks_list_order')),
  sortBy: verifySortKey(NetworkQueryKey, getLocalStorage('networks_list_sort')),
};

const SupportedNetworks: React.FC<Props> = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const [networkQuery, setNetworkQuery] = useState(initQuery);
  const [openAddNetworkDialog, setOpenAddNetworkDialog] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<INetworksList>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openRemoveNetworkDialog, setOpenRemoveNetworkDialog] = useState(false);

  useEffect(() => {
    document.title = t('supported_networks');
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      networks_list_order: networkQuery?.orderBy,
      networks_list_sort: networkQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkQuery?.orderBy, networkQuery?.sortBy]);

  const updateShopQuery = (newValue: any) => setNetworkQuery((value: any) => ({ ...value, ...newValue }));

  const [removeNetwork] = useRemoveNetworkMutation({
    refetchQueries: [ListNetworksDocument],
  });
  const { data: listNetworksRes, loading: loadingNetworksList } = useListNetworksQuery({
    fetchPolicy: 'cache-and-network',
  });

  const items = useMemo(() => {
    const items = listNetworksRes?.listNetworks || [];

    const _items = items
      .map((item) => {
        return {
          id: item.uuid,
          [NetworkQueryKey.Name]: item.name,
          [NetworkQueryKey.ChainId]: item.chainId,
          [NetworkQueryKey.TestMode]: item.testMode,
          [NetworkQueryKey.CreatedAt]: item.createdAt,
          [NetworkQueryKey.SubgraphUrl]: item.subgraphUrl,
          [NetworkQueryKey.TokenSymbol]: item.tokenSymbol,
        };
      })
      .filter((i) => (i[NetworkQueryKey.Name] || '').toLowerCase().includes((debounceValue || '').toLowerCase()));
    return _items;
  }, [listNetworksRes?.listNetworks, debounceValue]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenRemoveNetworkDialog = () => {
    handleCloseMenu();
    setOpenRemoveNetworkDialog(true);
  };

  const onCloseRemoveNetworkDialog = useCallback(async () => {
    setOpenRemoveNetworkDialog(false);
  }, []);

  const onOpenAddNetworkDialog = useCallback(() => {
    setOpenAddNetworkDialog(true);
  }, []);

  const onCloseAddUserDialog = useCallback(() => {
    setOpenAddNetworkDialog(false);
  }, []);

  const handleConfirm = async () => {
    try {
      await removeNetwork({
        variables: {
          uuid: selectedNetwork?.id ?? '',
        },
      });
      enqueueSnackbar(t('toast_message.removed_network'), {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleClickMoreMenu = useCallback(
    (row: INetworksList): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedNetwork(row);
      },
    []
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        width: 300,
        field: NetworkQueryKey.Name,
        headerName: t('name.thing'),
      },
      {
        width: 120,
        align: 'left',
        type: 'number',
        headerAlign: 'left',
        headerName: t('chain_id'),
        field: NetworkQueryKey.ChainId,
      },
      {
        width: 120,
        field: NetworkQueryKey.TokenSymbol,
        headerName: t('token_symbol'),
      },
      {
        width: 300,
        field: NetworkQueryKey.SubgraphUrl,
        headerName: t('subgraph_url'),
        renderCell: (params) => (
          <Link
            target="_blank"
            display="inline-block"
            overflow="hidden"
            textOverflow="ellipsis"
            href={params.row[NetworkQueryKey.SubgraphUrl]}
          >
            {params.row[NetworkQueryKey.SubgraphUrl]}
          </Link>
        ),
      },
      {
        width: 100,
        align: 'center',
        headerAlign: 'center',
        headerName: t('test_mode'),
        field: NetworkQueryKey.TestMode,
        renderCell: (params) => params.row[NetworkQueryKey.TestMode] && <CheckIcon color="primary" />,
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: NetworkQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 50,
        headerName: '',
        type: 'actions',
        field: t('actions'),
        disableReorder: true,
        renderCell: (params) => {
          return [
            <IconButton key="1" size="small" onClick={handleClickMoreMenu(params.row)}>
              <MoreVertIcon />
            </IconButton>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, [i18n.language]);

  const toolbarProps = {
    search,
    searchLabel: `${t('name.person')}...`,
    handleSearch,
  };

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.primary">{t('supported_networks')}</Typography>
      </Breadcrumbs>
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenRemoveNetworkDialog} data-testid="DeleteMenu">
          {t('delete')}
        </MenuItem>
      </Menu>
      <Box className={classes.wrapper}>
        <WrapperWithFab onClick={onOpenAddNetworkDialog}>
          <CustomCardTable
            cardTitle={t('supported_networks')}
            headerAction={
              <HeaderAction
                menus={[
                  {
                    title: t('add_network'),
                    onClick: onOpenAddNetworkDialog,
                  },
                ]}
              />
            }
            cardContent={
              <Box className={classes.wrapperTable}>
                <ListTable
                  noBorder
                  rows={items}
                  columns={columns}
                  sortingMode="client"
                  rowCount={items.length}
                  paginationMode="client"
                  tableName="adminManagement"
                  paginationModel={undefined}
                  isLoading={loadingNetworksList}
                  pageSizeOptions={[ITEMS_PER_PAGE]}
                  sort={{
                    sortBy: networkQuery.sortBy,
                    orderBy: networkQuery.orderBy,
                  }}
                  slots={{
                    toolbar: GridToolbarSearchByAPI,
                  }}
                  slotProps={{
                    toolbar: toolbarProps,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: ITEMS_PER_PAGE } },
                  }}
                  onSort={updateShopQuery}
                />
              </Box>
            }
          />
        </WrapperWithFab>
        <AddNetworkDialog open={openAddNetworkDialog} onClose={onCloseAddUserDialog} />
        <ConfirmationDialog
          open={openRemoveNetworkDialog}
          title={t('delete_network')}
          onConfirm={handleConfirm}
          onClose={onCloseRemoveNetworkDialog}
        />
      </Box>
    </UserLayout>
  );
};

export default SupportedNetworks;
