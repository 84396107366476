import { OrderBy } from '~/graphql/admin/types';
import { ParamsForRoute } from '~/types/common';

interface LocalStorageItems {}

export const setLocalStorageItems = (items: LocalStorageItems) => {
  for (const [key, value] of Object.entries(items)) {
    if (value !== null && value !== undefined) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }
};
export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const verifySortKey = (keys: any, value: any, defaultValue?: any) => {
  return Object.values(keys).includes(value) ? value : defaultValue || keys.CreatedAt;
};

export const verifyOrderKey = (orderKey?: any) => {
  return orderKey === OrderBy.Asc ? OrderBy.Asc : OrderBy.Desc;
};

export const generatePathname = <Route extends string>(route: Route, params: ParamsForRoute<Route>) =>
  (Object.keys(params) as Array<keyof ParamsForRoute<Route>>).reduce((acc: string, key) => {
    const regex = new RegExp(`:${String(key)}`, 'g');
    return acc.replace(regex, params[key as keyof ParamsForRoute<Route>] as string);
  }, route) as string;

export interface CancelPromiseResponse {
  cancelCallback: (() => void) | undefined;
  awaitCallback: Promise<any>;
}

export const cancelPromise = (callback: () => any): CancelPromiseResponse => {
  let cancelCallback: CancelPromiseResponse['cancelCallback'];
  const awaitCallback: CancelPromiseResponse['awaitCallback'] = new Promise((resolve, reject) => {
    cancelCallback = () => reject();
    callback().then((data: any) => {
      resolve(data);
    });
  });
  return { cancelCallback, awaitCallback };
};

export const getAddressLink = (blockExplorer: string | null = '', address: string) => {
  if (!!blockExplorer) {
    return blockExplorer + '/address/' + address;
  }
  return '';
};
