import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { GetOrganizationQuery, useGetOrganizationQuery } from '~/graphql/admin/types';

export interface OrganizationContextValue {
  loading: boolean;
  organization?: GetOrganizationQuery['getOrganization'];
}

export const OrganizationContext = createContext<OrganizationContextValue>({} as any);

export const useOrganization = () => useContext(OrganizationContext);

const OrganizationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id: orgId } = useParams();
  const { data: organizationRes, loading } = useGetOrganizationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: orgId || '',
    },
  });

  return (
    <OrganizationContext.Provider
      value={{
        loading,
        organization: organizationRes?.getOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
