import React, { useCallback, useMemo, useState } from 'react';

import { WalletConnectorDialog, useDisconnect, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import PublicIcon from '@mui/icons-material/Public';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import LanguageMenu from '../app-layout/user-layout/header-bar/language-menu';

import NavigatorItem, { NavigatorItemConfig } from './navigator-item';

import { colors } from '~/constants/colors';
import { useLogout } from '~/hooks/use-logout';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';
import { StyledComponentProps } from '~/types/material-ui';
import { truncateEthAddress } from '~/utils/string.utils';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    borderRadius: 4,
    height: 48,
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 40,
    '& img': {
      width: 23,
      height: 23,
    },
  },
  divider: {
    margin: 1,
  },
  itemText: {
    span: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
  },
  paper: {
    width: 290,
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
  },
  languageMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#757575',
    '.MuiTypography-root': {
      marginLeft: '17px',
    },
    '.MuiButtonBase-root': {
      padding: '0!important',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  config: NavigatorItemConfig[];
  isTemporarySideBar?: boolean;
  onCloseDrawer?: () => void;
}

const NavigatorMenu: React.FC<Props> = (props) => {
  const { config, isTemporarySideBar, onCloseDrawer = () => {} } = props;
  const firebaseUser = useFirebaseUser();
  const onLogout = useLogout();
  const { t, i18n } = useTranslation();
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { account: accountAddress } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [openWalletConnectorDialog, setOpenWalletConnectorDialog] = useState(false);
  const { disconnect } = useDisconnect();

  const LANGS = useMemo(
    () => ({
      en: t('english'),
      ja: t('japanese'),
    }),
    [t]
  );

  const onOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setLanguageMenuAnchorEl(event.currentTarget);
    },
    [setLanguageMenuAnchorEl]
  );
  const onCloseLanguageMenu = useCallback(() => {
    setLanguageMenuAnchorEl(null);
  }, [setLanguageMenuAnchorEl]);

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('copied'), {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, [enqueueSnackbar, t]);

  const onOpenWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(true);
  }, []);

  const onCloseWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(false);
  }, []);

  const renderNavigatorItems = useMemo(
    () => config.map((item, index) => <NavigatorItem item={item} key={index} />),
    [config]
  );

  const closeDrawer = () => {
    if (isTemporarySideBar) onCloseDrawer();
  };

  return (
    <List sx={{ paddingBottom: 0 }}>
      <Toolbar />
      <WalletConnectorDialog open={openWalletConnectorDialog} onClose={onCloseWalletConnectorDialog} />
      {isTemporarySideBar && (
        <>
          <MenuItem>
            <ListItemAvatar>
              <Avatar variant="rounded" src={firebaseUser?.photoURL || ''} />
            </ListItemAvatar>
            <ListItemText primary={firebaseUser?.displayName} secondary={firebaseUser?.email} />
          </MenuItem>
          <Button
            style={{ marginTop: 4, marginBottom: 8 }}
            fullWidth
            color="gray"
            variant="outlined"
            onClick={accountAddress ? disconnect : onOpenWalletConnectorDialog}
          >
            {accountAddress ? t('disconnect_wallet') : t('connect_wallet')}
          </Button>
          {!!accountAddress && (
            <CopyToClipboard text={accountAddress} onCopy={onCopyAddress}>
              <Typography sx={{ cursor: 'pointer' }}>{truncateEthAddress(accountAddress)}</Typography>
            </CopyToClipboard>
          )}
        </>
      )}
      <Box className={classes.sideBar} sx={{ height: !isTemporarySideBar ? 'calc(100vh - 104px)' : 'unset' }}>
        <Box sx={{ flex: 1, height: '100%' }} onClick={closeDrawer}>
          {renderNavigatorItems}
        </Box>
        {!isTemporarySideBar && (
          <>
            <Divider sx={{ margin: '0 0 20px' }} />
            <Button
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
              onClick={onOpenLanguageMenu}
              color="inherit"
              className={classes.languageMenu}
            >
              <Box sx={{ display: 'flex', paddingLeft: '8px', textTransform: 'capitalize' }}>
                <PublicIcon />
                <Typography>{LANGS[i18n.language as 'en' | 'ja']}</Typography>
              </Box>
              <ExpandMoreIcon />
            </Button>
            <LanguageMenu
              open={Boolean(languageMenuAnchorEl)}
              onClose={onCloseLanguageMenu}
              anchorEl={languageMenuAnchorEl}
            />
          </>
        )}
      </Box>
      {isTemporarySideBar && (
        <>
          <ListItemButton onClick={onLogout} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <LogoutIcon style={{ fill: colors.black }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={onOpenLanguageMenu} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <PublicIcon style={{ fill: colors.black }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={LANGS[i18n.language as 'en' | 'ja']} />
            <ExpandMoreIcon />
          </ListItemButton>
          <LanguageMenu
            open={Boolean(languageMenuAnchorEl)}
            onClose={onCloseLanguageMenu}
            anchorEl={languageMenuAnchorEl}
          />
        </>
      )}
    </List>
  );
};

export default NavigatorMenu;
