import { useMemo } from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import { env } from '~/env';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const cacheMember = new InMemoryCache({
  addTypename: false,
});

export const WithApolloClient: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const user = useFirebaseUser();

  const client = useMemo(() => {
    const asyncAuthLink = setContext(async (_) => {
      const token = !!user && (await user.getIdToken());
      const organization = localStorage.getItem('organizationUuid');
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          ...(organization ? { organization } : {}),
        },
      };
    });

    const memberLink = asyncAuthLink.concat(
      createUploadLink({
        uri: env.REACT_APP_API_ADMIN_SERVER,
        headers: {
          'Apollo-Require-Preflight': 'true',
        },
      })
    );

    return new ApolloClient({
      link: memberLink,
      cache: cacheMember,
    });
  }, [user]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
