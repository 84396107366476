import { Fragment, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '../dialog-close-button';

const maxWidthDialog = {
  sm: 446,
  md: 540,
  lg: 916,
};

const useStyles = makeStyles<{ width?: WidthDialog; maxWidthPaper?: number }>()((_, { width, maxWidthPaper }) => ({
  initDialog: {
    '.MuiPaper-root': {
      maxWidth: `${maxWidthPaper || maxWidthDialog?.[width as WidthDialog] || 500}px`,
      '.MuiDialogActions-root': {
        padding: '0px 24px 20px',
        '& > :not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
}));

type WidthDialog = 'sm' | 'md' | 'lg';

interface Props extends DialogProps {
  width?: WidthDialog;
  dialogTitle: ReactNode;
  maxWidthPaper?: number;
  actions?: JSX.Element[];
  dialogContent: ReactNode;
  hideCloseButton?: boolean;
  disabledCloseButton?: boolean;
  onClose: () => void;
}

const CustomDialog: React.FC<Props> = (props) => {
  const {
    width,
    onClose,
    className,
    actions: _,
    maxWidthPaper,
    dialogTitle: __,
    dialogContent: ___,
    ...others
  } = props;

  const { classes, cx } = useStyles({ width, maxWidthPaper });

  const handleCloseDialog: DialogProps['onClose'] = (_, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      {...others}
      fullWidth
      scroll="body"
      className={cx(className, classes.initDialog)}
      onClose={handleCloseDialog}
    >
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<Props> = (props) => {
  const { dialogTitle, dialogContent, hideCloseButton, actions, disabledCloseButton, onClose } = props;

  return (
    <>
      {!hideCloseButton && (
        <DialogCloseButton onClick={onClose} data-testid="close-button" disabled={disabledCloseButton}>
          <CloseIcon />
        </DialogCloseButton>
      )}
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      {!!actions?.length && (
        <DialogActions>
          {actions.map((action, idx) => (
            <Fragment key={idx}>{action}</Fragment>
          ))}
        </DialogActions>
      )}
    </>
  );
};

export default CustomDialog;
