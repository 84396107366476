import { useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from '../../icons/images/home-icon.svg';

import { AppRouteEnum } from '~/enum/AppRouteEnum';

const HomeBtn = () => {
  const navigate = useNavigate();

  return (
    <HomeIcon
      data-testid="HomeIcon"
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(AppRouteEnum.Dashboard)}
      width={14}
      height={14}
    />
  );
};

export default HomeBtn;
