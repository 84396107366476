import { FC, useEffect, useRef, useState } from 'react';

import { IpfsImg } from '@gu-corp/react-ipfs-media';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

export type LoadSquareImageType = Event & { currentTarget?: { naturalWidth: number; naturalHeight: number } };

interface SquareImageProps {
  src: string;
  isNFT?: boolean;
  borderRadius?: number;
  onClick?: () => void;
  onLoad?: (e: LoadSquareImageType) => void;
}

const useStyles = makeStyles<{ borderRadius: number }>()((_, { borderRadius }) => ({
  wrapperImage: {
    width: '100%',
    cursor: 'pointer',
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: `${borderRadius}px`,
  },
  image: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'contain',
  },
}));

const SquareImage: FC<SquareImageProps> = ({ src, isNFT, borderRadius = 0, onClick, onLoad }) => {
  const { classes } = useStyles({ borderRadius });

  const [loading, setLoading] = useState(true);

  const ipfsImgRef = useRef<HTMLImageElement>(null);

  const handleClick = () => {
    if (!!onClick) {
      onClick();
    }
  };

  const handleLoad = (e: any) => {
    setLoading(false);
    if (!!onLoad) {
      onLoad(e);
    }
  };

  useEffect(() => {
    const ipfsImgNode = ipfsImgRef.current;
    if (!!src && isNFT) {
      ipfsImgNode?.addEventListener('load', handleLoad);
    }
    return () => {
      ipfsImgNode?.removeEventListener('load', handleLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, isNFT]);

  return (
    <Box className={classes.wrapperImage} onClick={handleClick}>
      {!!src ? (
        isNFT ? (
          <IpfsImg ref={ipfsImgRef} loading="lazy" url={src} className={classes.image} />
        ) : (
          <img src={src} alt="Thumbnails" loading="lazy" className={classes.image} onLoad={handleLoad} />
        )
      ) : null}
      {loading && <Skeleton variant="rectangular" className={classes.image} />}
    </Box>
  );
};

export default SquareImage;
