import { FC, memo, useEffect, useMemo, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HomeBtn from '~/components/home-btn';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { useOrganization } from '~/contexts/OrganizationProvider';
import { useSupportedNetworks } from '~/contexts/SupportedNetworkProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import {
  MyShopCollectionQueryKey,
  MyShopQueryKey,
  QueryOperator,
  useGetMyShopQuery,
  useListMyShopCollectionsQuery,
} from '~/graphql/admin/types';
import {
  generatePathname,
  getAddressLink,
  getLocalStorage,
  setLocalStorageItems,
  verifyOrderKey,
  verifySortKey,
} from '~/utils/common';
import { truncateEthAddress } from '~/utils/string.utils';

export interface DisplayedCollectionsList {
  id: string;
  url?: string;
  [MyShopCollectionQueryKey.Name]?: string;
  [MyShopCollectionQueryKey.Desc]?: string;
  [MyShopCollectionQueryKey.NameJa]?: string;
  [MyShopCollectionQueryKey.DescJa]?: string;
  [MyShopCollectionQueryKey.CollectionUuid]?: string;
  [MyShopCollectionQueryKey.CollectionSymbol]?: string;
  [MyShopCollectionQueryKey.CollectionNetwork]: string;
  [MyShopCollectionQueryKey.CollectionCreatedAt]?: string;
  [MyShopCollectionQueryKey.CollectionOwnerAddress]: string;
  [MyShopCollectionQueryKey.CollectionContractAddress]?: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ListMyShopCollections: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id: orgId, shopId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { organization } = useOrganization();
  const { supportedNetworks } = useSupportedNetworks();

  const [myShopCollectionsQuery, setMyShopCollectionsQuery] = useState({
    page: 1,
    searchText: '',
    limit: ITEMS_PER_PAGE,
    sortBy: verifySortKey(MyShopQueryKey, getLocalStorage('list_my_shop_collections_sort')),
    orderBy: verifyOrderKey(getLocalStorage('list_my_shop_collections_order')),
    where: {
      fields: [
        {
          value: [shopId ?? ''],
          operator: QueryOperator.Contains,
          key: MyShopCollectionQueryKey.ShopUuid,
        },
      ],
    },
  });

  const updateMyShopCollectionsQuery = (newValue: any) => {
    setMyShopCollectionsQuery((value: any) => {
      return { ...value, ...newValue };
    });
  };

  const { data: shopDataRes } = useGetMyShopQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: shopId || '',
    },
  });
  const shopData = shopDataRes?.getMyShop;
  const isShop = shopData?.shopType === 'shop';

  const { data: dataShopCollections, loading: loadingListMyShopCollections } = useListMyShopCollectionsQuery({
    variables: myShopCollectionsQuery,
    fetchPolicy: 'cache-and-network',
    onError(error) {
      enqueueSnackbar(error.message || t('toast_message.error'), { variant: 'error' });
    },
  });

  const { items, pagination } = useMemo(() => {
    const items = dataShopCollections?.listMyShopCollections?.items || [];
    const pagination: ListTablePagination = dataShopCollections?.listMyShopCollections?.pagination || {};

    const _items: DisplayedCollectionsList[] = items.map((item) => {
      const { collection } = item;
      return {
        id: item.uuid,
        order: item.order,
        [MyShopCollectionQueryKey.CollectionName]: collection?.name,
        [MyShopCollectionQueryKey.CollectionUuid]: collection?.uuid,
        url: env.REACT_APP_API_MEDIA + '/' + collection?.images?.[0],
        [MyShopCollectionQueryKey.Name]: item.name || collection?.name,
        [MyShopCollectionQueryKey.CollectionSymbol]: collection?.symbol,
        [MyShopCollectionQueryKey.CollectionNetwork]: collection?.network,
        [MyShopCollectionQueryKey.NameJa]: item.nameJa || collection?.name,
        [MyShopCollectionQueryKey.Desc]: item.desc || collection?.desc || '',
        [MyShopCollectionQueryKey.CollectionCreatedAt]: collection?.createdAt,
        [MyShopCollectionQueryKey.DescJa]: item.descJa || collection?.descJa || '',
        [MyShopCollectionQueryKey.CollectionOwnerAddress]: collection?.ownerAddress,
        [MyShopCollectionQueryKey.CollectionContractAddress]: collection?.contractAddress,
      } as DisplayedCollectionsList;
    });
    return { items: _items, pagination };
  }, [dataShopCollections]);

  const columns: GridColDef<DisplayedCollectionsList>[] = useMemo(
    () => [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} />
            </Box>
          );
        },
      },
      {
        width: 250,
        field: MyShopCollectionQueryKey.Name,
        headerName: t('collection.collection_caption_en'),
      },
      {
        width: 250,
        field: MyShopCollectionQueryKey.NameJa,
        headerName: t('collection.collection_caption_ja'),
      },
      {
        width: 250,
        headerName: t('collection.collection_name'),
        field: MyShopCollectionQueryKey.CollectionName,
      },
      {
        width: 200,
        headerName: t('symbol'),
        field: MyShopCollectionQueryKey.CollectionSymbol,
      },
      {
        width: 150,
        getApplyQuickFilterFn: undefined,
        headerName: t('collection.contract_address'),
        field: MyShopCollectionQueryKey.CollectionContractAddress,
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                <Link
                  target="_blank"
                  href={getAddressLink(
                    supportedNetworks?.[row[MyShopCollectionQueryKey.CollectionNetwork]]?.blockExplorer,
                    formattedValue
                  )}
                >
                  {truncateEthAddress(formattedValue)}
                </Link>
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
      },
      {
        width: 150,
        getApplyQuickFilterFn: undefined,
        headerName: t('collection.owner_address'),
        field: MyShopCollectionQueryKey.CollectionOwnerAddress,
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                {truncateEthAddress(formattedValue)}
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
      },
      {
        width: 200,
        headerName: t('collection.network'),
        field: MyShopCollectionQueryKey.CollectionNetwork,
        valueFormatter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        field: MyShopCollectionQueryKey.CollectionCreatedAt,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
    ],
    [supportedNetworks, t]
  );

  useEffect(() => {
    setLocalStorageItems({
      list_my_shop_collections_order: myShopCollectionsQuery?.orderBy,
      list_my_shop_collections_sort: myShopCollectionsQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShopCollectionsQuery?.orderBy, myShopCollectionsQuery?.sortBy]);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          underline="hover"
          color="text.primary"
          component={RouterLink}
          to={AppRouteEnum.ListOrganizations}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('list_organizations')}
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          component={RouterLink}
          sx={{ display: 'flex', alignItems: 'center' }}
          to={{
            pathname: generatePathname(AppRouteEnum.OrganizationDetail, { id: orgId || '' }),
            search: `?tab=${isShop ? 1 : 2}`,
          }}
        >
          {organization?.name || ''}
        </Link>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {t('shops')}
        </Typography>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {shopData?.siteSetting?.title}
        </Typography>
      </Breadcrumbs>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={t('collections')}
          cardContent={
            <ListTable
              isMenu
              noBorder
              rows={items}
              columns={columns}
              paginationData={pagination}
              tableName="list_my_shop_collections"
              isLoading={loadingListMyShopCollections}
              search={myShopCollectionsQuery.searchText}
              searchLabel={t('collection.collection_name')}
              onPagination={updateMyShopCollectionsQuery}
              onSearch={(v) => updateMyShopCollectionsQuery({ page: 1, searchText: v || '' })}
              sort={{
                sortBy: myShopCollectionsQuery.sortBy,
                orderBy: myShopCollectionsQuery.orderBy,
              }}
              onSort={updateMyShopCollectionsQuery}
            />
          }
        />
      </Box>
    </UserLayout>
  );
};

export default memo(ListMyShopCollections);
