import { useTranslation } from 'react-i18next';

import AuthLayout from '~/components/auth-layout';

interface Props {
  code: '404';
}

const CODE = {
  '404': {
    message: 'not_found',
  },
};

const ErrorPage: React.FC<Props> = (props) => {
  const { code } = props;
  const { t } = useTranslation();
  return (
    <AuthLayout headerTitle={t(CODE[code].message)} hideLanguage>
      <div>
        {t('error_code')}: {code}
      </div>
    </AuthLayout>
  );
};

export default ErrorPage;
