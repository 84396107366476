import { Outlet } from 'react-router-dom';

import OrganizationProvider from '~/contexts/OrganizationProvider';
import SupportedNetworksProvider from '~/contexts/SupportedNetworkProvider';

const OrganizationWrapper = () => {
  return (
    <OrganizationProvider>
      <SupportedNetworksProvider>
        <Outlet />
      </SupportedNetworksProvider>
    </OrganizationProvider>
  );
};

export default OrganizationWrapper;
