import { useCallback, useEffect, useMemo, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { GridColDef } from '@mui/x-data-grid';
import { JsonRpcProvider } from 'ethers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { API_MEDIA, ITEMS_PER_PAGE } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworkProvider';
import { CollectionQueryKey, useListOrganizationCollectionsQuery } from '~/graphql/admin/types';
import {
  cancelPromise,
  CancelPromiseResponse,
  getAddressLink,
  getLocalStorage,
  setLocalStorageItems,
  verifyOrderKey,
  verifySortKey,
} from '~/utils/common';
import { truncateEthAddress } from '~/utils/string.utils';

export type CollectionData = {
  id: string;
  url?: string;
  name: string;
  symbol: string;
  status?: string;
  network: string;
  createdAt?: string;
  ownerAddress?: string;
  contractAddress: string;

  //new key
  [CollectionQueryKey.Name]?: string;
  [CollectionQueryKey.Symbol]?: string;
  [CollectionQueryKey.Network]?: string;
  [CollectionQueryKey.CreatedAt]?: string;
  [CollectionQueryKey.OwnerAddress]?: string;
  [CollectionQueryKey.ContractAddress]?: string;
};

const useStyles = makeStyles()(() => ({
  content: {
    minHeight: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '60px',
    '& > .MuiTypography-h5': {
      fontSize: '24px',
      fontWeight: 400,
      marginBottom: '16px',
    },
    '& > div': {
      minHeight: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

let getCollectionsPromise: CancelPromiseResponse;

const clearState = () => {
  window.history.replaceState(null, '');
};

const ListCollections: React.FC = () => {
  const { supportedNetworks, loading: loadingSupportedNetwork } = useSupportedNetworks();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id: orgId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<CollectionData[]>([]);
  const [isLoadingCollections, setIsLoadingCollection] = useState(true);

  const [collectionsQuery, setCollectionsQuery] = useState({
    page: 1,
    searchText: '',
    limit: ITEMS_PER_PAGE,
    organizationUuid: orgId || '',
    sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('list_collections_sort')),
    orderBy: verifyOrderKey(getLocalStorage('list_collections_order')),
  });

  const updateCollectionsQuery = (newValue: any) => setCollectionsQuery((value: any) => ({ ...value, ...newValue }));

  useEffect(() => {
    setLocalStorageItems({
      list_collections_sort: collectionsQuery?.sortBy,
      list_collections_order: collectionsQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsQuery?.orderBy, collectionsQuery?.sortBy]);

  const { data: dataListCollections, loading } = useListOrganizationCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: collectionsQuery,
    skip: loadingSupportedNetwork,
  });

  const { items, pagination } = useMemo(() => {
    const items = dataListCollections?.listOrganizationCollections?.items || [];
    const pagination: ListTablePagination = dataListCollections?.listOrganizationCollections?.pagination || {};
    return { items, pagination };
  }, [dataListCollections]);

  const columns: GridColDef<CollectionData>[] = useMemo(
    () => [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} />
            </Box>
          );
        },
      },
      {
        width: 300,
        field: CollectionQueryKey.Name,
        headerName: t('collection.collection_name'),
      },
      {
        width: 150,
        headerName: t('symbol'),
        field: CollectionQueryKey.Symbol,
      },
      {
        width: 150,
        getApplyQuickFilterFn: undefined,
        field: CollectionQueryKey.OwnerAddress,
        headerName: t('collection.owner_address'),
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            {truncateEthAddress(row.ownerAddress!)}
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        width: 150,
        getApplyQuickFilterFn: undefined,
        field: CollectionQueryKey.ContractAddress,
        headerName: t('collection.contract_address'),
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            <Link
              target="_blank"
              href={getAddressLink(supportedNetworks?.[row.network]?.blockExplorer, formattedValue)}
            >
              {truncateEthAddress(formattedValue)}
            </Link>
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        width: 250,
        field: CollectionQueryKey.Network,
        headerName: t('collection.network'),
        valueGetter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: CollectionQueryKey.CreatedAt,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
    ],
    [supportedNetworks, t]
  );

  useEffect(() => {
    document.title = t('collections');
  }, [t]);

  const setDataGridRows = useCallback(async () => {
    try {
      if (getCollectionsPromise?.cancelCallback) {
        getCollectionsPromise.cancelCallback();
      }
      getCollectionsPromise = cancelPromise(async () => {
        try {
          setIsLoadingCollection(true);
          let newCollections: CollectionData[] = [];
          if (items && !loading) {
            newCollections = await Promise.all(
              items.map(async ({ uuid, name, symbol, images, network, createdAt, contractAddress }) => {
                const provider = new JsonRpcProvider(supportedNetworks?.[network]?.rpcUrl);
                const contractNotSigner = ERC721G__factory.connect(contractAddress, provider as any);
                const ownerAddress = await contractNotSigner.owner();

                return {
                  name,
                  symbol,
                  network,
                  id: uuid,
                  createdAt,
                  ownerAddress,
                  contractAddress,
                  url: API_MEDIA + (images?.[0] || ''),

                  //new key
                  [CollectionQueryKey.Name]: name,
                  [CollectionQueryKey.Symbol]: symbol,
                  [CollectionQueryKey.CreatedAt]: createdAt,
                  [CollectionQueryKey.OwnerAddress]: ownerAddress || undefined,
                  [CollectionQueryKey.ContractAddress]: contractAddress,
                  [CollectionQueryKey.Network]: network,
                };
              })
            );
            setIsLoadingCollection(false);
          }
          return newCollections;
        } catch (err: any) {
          enqueueSnackbar(err.message || t('toast_message.error'), { variant: 'error' });
          return [];
        }
      });
      const newRows = await getCollectionsPromise.awaitCallback;
      setRows(newRows as CollectionData[]);
    } catch (err: any) {}
  }, [items, loading, supportedNetworks, enqueueSnackbar, t]);

  useEffect(() => {
    setDataGridRows();
  }, [setDataGridRows]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearState);
    return () => {
      window.removeEventListener('beforeunload', clearState);
    };
  }, []);

  const loadingData = loading || isLoadingCollections;

  return (
    <Box className={classes.content}>
      <CustomCardTable
        cardTitle={t('collections')}
        cardContent={
          <ListTable
            isMenu
            noBorder
            rows={rows}
            columns={columns}
            isCollectionScreen
            isLoading={loadingData}
            tableName="list_collections"
            searchLabel={t('collection.collection_name')}
            // new
            search={collectionsQuery.searchText}
            onSearch={(v) => updateCollectionsQuery({ page: 1, searchText: v || '' })}
            paginationData={pagination}
            onPagination={updateCollectionsQuery}
            sort={{
              sortBy: collectionsQuery.sortBy,
              orderBy: collectionsQuery.orderBy,
            }}
            onSort={(value = {}) => updateCollectionsQuery(value)}
          />
        }
      />
    </Box>
  );
};

export default ListCollections;
