import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import LoaderCenter from '~/components/loader-center';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { MAX_NUMBER } from '~/constants/common';
import {
  GetSystemSettingsDocument,
  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,
} from '~/graphql/admin/types';
import { useNotify } from '~/hooks/useNotify';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '16px',
  },
}));

const schema = yup.object({
  maximumPoint: yup.string().maxNumber(MAX_NUMBER).required(),
});

export interface FormValues extends yup.InferType<typeof schema> {}

const Points = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError, showSuccess } = useNotify();

  const [updateSystemSettings] = useUpdateSystemSettingsMutation({ refetchQueries: [GetSystemSettingsDocument] });
  const { loading: loadingSystemSettings } = useGetSystemSettingsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) =>
      reset({
        maximumPoint: data.getSystemSettings.maximumPoint.toString(),
      }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      maximumPoint: '',
    },
    resolver: yupResolver(schema),
  });

  const isDirtyCurrency = !!Object.keys(dirtyFields).length;

  const onSubmit = async (data: FormValues) => {
    try {
      await updateSystemSettings({
        variables: {
          input: {
            maximumPoint: parseFloat(data.maximumPoint),
          },
        },
      });
      reset(data);
      showSuccess('toast_message.updated_successfully');
    } catch (err: any) {
      showError(err);
    }
  };

  return (
    <CustomCardTable
      cardTitle={t('settings.points.maximum_points_balance')}
      cardContent={
        <Box className={classes.wrapper}>
          {loadingSystemSettings ? (
            <LoaderCenter />
          ) : (
            <Controller
              name="maximumPoint"
              control={control}
              render={({ field }) => (
                <NumberStringTextField
                  fullWidth
                  variant="outlined"
                  disabled={isSubmitting}
                  error={!!errors.maximumPoint?.message}
                  label={t('settings.points.maximum_points_balance')}
                  helperText={getErrorText(errors.maximumPoint?.message, t)}
                  {...field}
                />
              )}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%', marginTop: '24px' }}>
            <Button
              variant="contained"
              disabled={isSubmitting || !isDirtyCurrency}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('update')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default Points;
