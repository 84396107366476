import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import {
  DataGridProProps,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowsProp,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
  jaJP,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDataGrid from './CustomDataGrid';
import GridToolbarSearchByAPI, { GridToolbarProps } from './GridToolbarSearchByAPI';

import { ITEMS_PER_PAGE } from '~/constants/common';
import { OrderByGrid, ReverseOrderByGrid } from '~/constants/tableKeys';
import { OrderBy } from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';

declare module '@mui/x-data-grid-pro' {
  interface NoRowsOverlayPropsOverrides {
    message: string;
  }
}

export interface ListTablePagination {
  totalItems?: number;
  itemsPerPage?: number;
  totalPages?: number;
  currentPage?: number;
}
export interface SortItem {
  sortBy?: string;
  orderBy?: string;
}

export interface IHandleListTable extends SortItem {
  page?: number;
  limit?: number;
  searchText?: string;
}

type DataGridProComponent<R extends GridValidRowModel = any> = DataGridProProps<R> & {
  search?: string;
  isMenu?: boolean;
  isLoading: boolean;
  noBorder?: boolean;
  tableName?: string;
  notSquare?: boolean;
  orderPriority?: boolean;
  sort?: SortItem;
  searchLabel?: string;
  isShopScreen?: boolean;
  isCollectionScreen?: boolean;
  columns: GridColDef<R>[];
  rows: GridRowsProp<R>;
  actionsToolbar?: {
    menuItems?: GridToolbarProps['menuItems'];
  };
  paginationData?: ListTablePagination;
  onSearch?: (value?: string) => void;
  onSort?: (sort: IHandleListTable) => void;
  onRowUpdate?: (newRow: R, oldRow: R) => R | Promise<R>;
  onColumnOrderChange?: GridEventListener<'columnOrderChange'>;
  onPagination?: (paging: { page: number; limit: number }) => void;
};

const useStyles = makeStyles<{
  noBorder?: boolean;
}>()((theme, { noBorder }) => ({
  wrapperListTable: {
    backgroundColor: theme.palette.common.white,
    '.MuiDataGrid-root': {
      borderRadius: '8px',
      border: noBorder ? 'none' : '',
      '.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer .MuiBadge-root .MuiBadge-badge': {
        display: 'none',
      },
    },
    '.MuiDataGrid-toolbarContainer': noBorder
      ? {
          padding: '0',
          margin: '0',
        }
      : {},
    '.MuiBox-root': {
      border: noBorder ? 'none' : '',
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const orderSort: GridSortItem = { field: 'order', sort: 'asc' };

const ListTable = <R extends GridValidRowModel = any>({
  sort,
  rows,
  slots,
  search,
  isMenu,
  columns,
  noBorder,
  notSquare,
  tableName,
  isLoading,
  searchLabel,
  isShopScreen,
  orderPriority,
  actionsToolbar,
  paginationData,
  isCollectionScreen,
  onRowUpdate,
  onSort = () => {},
  onSearch = () => {},
  onColumnOrderChange,
  onPagination = () => {},
  ...others
}: DataGridProComponent<R>): JSX.Element => {
  const { i18n } = useTranslation();
  const { classes } = useStyles({ noBorder });

  const [valueSearch, setValueSearch] = useState(search);
  const debounceValue = useDebounce(valueSearch, 1000);

  useEffect(() => {
    if (search !== debounceValue) {
      onSearch(debounceValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    if (search !== valueSearch) {
      setValueSearch(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const sortParams = useMemo<GridSortModel>(() => {
    const _s = orderPriority ? [orderSort] : [];
    if (sort && sort.sortBy)
      _s.push({
        field: sort.sortBy,
        sort: ReverseOrderByGrid[(sort.orderBy as OrderBy) || OrderBy.Desc],
      });
    return _s;
  }, [orderPriority, sort]);

  const toolbarProps = {
    searchLabel,
    search: valueSearch,
    ...(actionsToolbar ? actionsToolbar : {}),
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => {
      setValueSearch(e.target.value);
    },
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    if (!model[0]) {
      if (columns.find((i) => i.field === sort?.sortBy)) {
        const orderBy = OrderByGrid[sort?.orderBy?.toLowerCase?.() === 'desc' ? 'asc' : 'desc'] || OrderByGrid.desc;
        onSort({ sortBy: sort?.sortBy, orderBy });
      }
    } else {
      const orderBy = OrderByGrid[model[0]?.sort || 'desc'] || OrderByGrid.desc;
      onSort({ sortBy: model[0].field, orderBy });
    }
  };

  const handlePagination = async (model: GridPaginationModel) => {
    await onPagination({ page: model.page + 1, limit: model.pageSize });
  };

  const handleProcessRowUpdate = !!onRowUpdate ? (newRow: R, oldRow: R) => onRowUpdate(newRow, oldRow) : undefined;

  return (
    <Box className={classes.wrapperListTable}>
      <CustomDataGrid
        pagination
        rows={rows}
        rowHeight={84}
        columns={columns}
        disableColumnMenu
        loading={isLoading}
        tableName={tableName}
        sortModel={sortParams}
        rowCount={paginationData?.totalItems || 0}
        paginationModel={{
          page: (paginationData?.currentPage || 1) - 1,
          pageSize: paginationData?.itemsPerPage || ITEMS_PER_PAGE,
        }}
        pageSizeOptions={[ITEMS_PER_PAGE]}
        sortingMode={orderPriority ? 'client' : 'server'}
        paginationMode={orderPriority ? 'client' : 'server'}
        isRowSelectable={isRowSelectable}
        onColumnOrderChange={onColumnOrderChange}
        onSortModelChange={handleSortModelChange}
        processRowUpdate={handleProcessRowUpdate}
        onPaginationModelChange={handlePagination}
        slots={{
          toolbar: GridToolbarSearchByAPI,
          ...(!!slots ? slots : {}),
        }}
        slotProps={{
          toolbar: toolbarProps,
        }}
        localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
        {...others}
      />
    </Box>
  );
};

const isRowSelectable = () => false;

export default ListTable;
