import { AdminRole } from '~/graphql/admin/types';

export const ADMIN_ACTIONS = {
  ADD: {
    [AdminRole.Owner]: [AdminRole.Administrator, AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Administrator]: [AdminRole.Administrator, AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Manager]: [AdminRole.Operator],
    [AdminRole.Operator]: [] as AdminRole[],
  },
  EDIT: {
    [AdminRole.Owner]: [AdminRole.Owner, AdminRole.Administrator, AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Administrator]: [AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Manager]: [] as AdminRole[],
    [AdminRole.Operator]: [] as AdminRole[],
  },
  DELETE: {
    [AdminRole.Owner]: [AdminRole.Administrator, AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Administrator]: [AdminRole.Manager, AdminRole.Operator],
    [AdminRole.Manager]: [AdminRole.Operator],
    [AdminRole.Operator]: [] as AdminRole[],
  },
};
