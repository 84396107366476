import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { makeStyles } from 'tss-react/mui';

interface CardProps {
  reverse?: boolean;
  cardTitle?: ReactNode;
  headerAction?: ReactNode;
  cardContent?: ReactNode;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiPaper-root': {
      borderRadius: '8px',
      boxShadow: 'none',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      minHeight: '52px',
      padding: '0px 16px',
      borderBottom: '1px solid #D7D7D7',
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardContent-root': {
      paddingBottom: '0',
      '&:last-child': {
        paddingBottom: '0',
      },
    },
  },
}));

const CustomCardTable: React.FC<CardProps> = (props) => {
  const { cardTitle, headerAction, cardContent } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={cardTitle}
          titleTypographyProps={{
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '0.1px',
            color: '#111',
          }}
          action={headerAction}
        />
        <CardContent>{cardContent}</CardContent>
      </Card>
    </Box>
  );
};

export default CustomCardTable;
