import { GridSortDirection } from '@mui/x-data-grid-pro';

import { OrderBy } from '~/graphql/admin/types';

export const OrderByGrid = {
  asc: OrderBy.Asc,
  desc: OrderBy.Desc,
};

export const ReverseOrderByGrid: { [key in OrderBy]: GridSortDirection } = {
  [OrderBy.Asc]: 'asc',
  [OrderBy.Desc]: 'desc',
};
