import { ChangeEventHandler, ComponentProps, ForwardedRef, forwardRef } from 'react';

import TextField from '@mui/material/TextField';

export interface INumberStringTextField extends ComponentProps<typeof TextField> {
  decimal?: 1 | 2 | 3;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const formatNumber = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const NumberStringTextField = forwardRef((props: INumberStringTextField, ref: ForwardedRef<HTMLDivElement>) => {
  const { value, decimal, onChange, ...others } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    const newValue = event.target.value;
    const removeComma = newValue.replace(/,/g, '');
    // eslint-disable-next-line no-useless-escape
    const regexPattern = decimal ? new RegExp(`^$|^\\d+\\.?\\d{0,${decimal}}$`) : /^\d*$/;
    if (!regexPattern.test(removeComma)) {
      return;
    }
    const removeNegative = removeComma.toString().replace(/-/g, '');
    const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
    event.target.value = removeZero;
    onChange(event);
  };

  return <TextField fullWidth ref={ref} value={formatNumber(value as string)} {...others} onChange={handleChange} />;
});
