import React, { createContext, useContext } from 'react';

import AccessDenied from '~/components/access-denied';
import ScreenLoading from '~/components/screen-loading';
import { Admin, useGetMeQuery } from '~/graphql/admin/types';

const AccountContext = createContext<{
  account?: Admin | null;
}>({} as any);

export const useAccount = () => useContext(AccountContext);

export const WithAccount: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { data: getMeData, error } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const isForbidden = (error?.graphQLErrors?.[0]?.extensions?.originalError as any)?.statusCode === 403;

  if (!getMeData?.getMe && !isForbidden) {
    return <ScreenLoading />;
  }

  if (isForbidden) {
    return <AccessDenied />;
  }

  return (
    <AccountContext.Provider
      value={{
        account: getMeData?.getMe,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
