import { makeStyles } from 'tss-react/mui';

export const useEditPlanDialogStyles = makeStyles()(() => ({
  section: {
    gap: '20px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  item: {
    '&:first-child': {
      flex: 0.4,
      fontWeight: 500,
      '.MuiTypography-body1': {
        fontWeight: 500,
      },
    },
    '&:last-child': {
      flex: 0.6,
      gap: '10px',
    },
  },
}));
