import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ListCollections from './details/ListCollections';
import ListShops from './details/ListShops';
import OrganizationInfo from './details/OrganizationInfo';

import UserLayout from '~/components/app-layout/user-layout';
import HomeBtn from '~/components/home-btn';
import LoaderCenter from '~/components/loader-center';
import { useOrganization } from '~/contexts/OrganizationProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';

export const shopStatus = (t: TFunction<'translation', undefined>) => ({
  OPEN: {
    label: t('open'),
    color: 'success',
  },
  CLOSE: {
    label: t('close'),
    color: 'error',
  },
});

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    flex: 1,
    borderBottom: 1,
    borderColor: 'divider',
  },
}));

export const organizationDetailTab = {
  info: {
    value: 0,
    label: 'basic',
  },
  shop: {
    value: 1,
    label: 'shops',
  },
  'member-site': {
    value: 2,
    label: 'member_sites',
  },
  collection: {
    value: 3,
    label: 'collections',
  },
};

const OrganizationDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, theme } = useStyles();
  const { organization, loading } = useOrganization();

  const [tab, setTab] = useState(organizationDetailTab.info.value);

  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: any) => {
    setTab(value);
    navigate(`${location.pathname}?tab=${value}`);
  };

  const renderContent = useMemo(() => {
    switch (tab) {
      case organizationDetailTab.info.value:
        return <OrganizationInfo organizationData={organization!} />;
      case organizationDetailTab.shop.value:
        return <ListShops key="shop" type="shop" />;
      case organizationDetailTab['member-site'].value:
        return <ListShops key="member" type="member" />;
      case organizationDetailTab.collection.value:
        return <ListCollections />;
      default:
        return <LoaderCenter />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, organization, t]);

  useEffect(() => {
    document.title = t('list_organizations');
  }, [t]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(Number(tabFromUrl));
    }
  }, [location.search]);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.ListOrganizations}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('list_organizations')}
        </Link>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {organization?.name || ''}
        </Typography>
      </Breadcrumbs>
      <Box className={classes.wrapper}>
        {loading ? (
          <LoaderCenter />
        ) : (
          <>
            <Box sx={{ flex: 1, borderBottom: 1, borderColor: 'divider', margin: '0 24px 24px 0', width: '100%' }}>
              <Tabs
                variant={isSmScreen ? 'fullWidth' : undefined}
                value={tab}
                onChange={handleChangeTab}
                className={classes.tabs}
              >
                {Object.values(organizationDetailTab).map((item) => (
                  <Tab key={item.value} label={t(item.label)} />
                ))}
              </Tabs>
            </Box>
            {renderContent}
          </>
        )}
      </Box>
    </UserLayout>
  );
};

export default OrganizationDetail;
