import { useEffect, useMemo, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { GridColDef, jaJP } from '@mui/x-data-grid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HomeBtn from '~/components/home-btn';
import ListTable, { ListTablePagination } from '~/components/list-table';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { OrganizationQueryKey, OrganizationStatus, useListOrganizationsQuery } from '~/graphql/admin/types';
import { generatePathname, getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export interface DisplayedOrganizationsData {
  id: string;
  [OrganizationQueryKey.CreatedAt]: Date;
  [OrganizationQueryKey.Status]: OrganizationStatus;
  [OrganizationQueryKey.Name]: string | null | undefined;
  [OrganizationQueryKey.ContactEmail]: string | null | undefined;
}

const getInitQuery = {
  page: 1,
  limit: ITEMS_PER_PAGE,
  searchText: '',
  sortBy: verifySortKey(OrganizationQueryKey, getLocalStorage('organizations_list_sort')),
  orderBy: verifyOrderKey(getLocalStorage('organizations_list_order')),
  where: {},
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTopTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 8px 8px',
  },
  shopURL: {
    color: '#000000DE',
    textDecoration: 'none',
    '&:hover': {
      color: '#1976d2',
      textDecoration: 'underline',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

const ListOrganizations = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('list_organizations');
  }, [t]);

  const [organizationQuery, setOrganizationQuery] = useState(getInitQuery);

  useEffect(() => {
    setLocalStorageItems({
      organizations_list_order: organizationQuery?.orderBy,
      organizations_list_sort: organizationQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationQuery?.orderBy, organizationQuery?.sortBy]);

  const updateOrganizationQuery = (newValue: any) => setOrganizationQuery((value: any) => ({ ...value, ...newValue }));

  const { data: listOrganizationsRes, loading: loadingListOrganizations } = useListOrganizationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: organizationQuery,
  });

  const { items, pagination } = useMemo(() => {
    const items = listOrganizationsRes?.listOrganizations?.items || [];
    const pagination: ListTablePagination = listOrganizationsRes?.listOrganizations?.pagination || {};

    const _items: DisplayedOrganizationsData[] = items.map((item) => {
      return {
        id: item.uuid,
        [OrganizationQueryKey.Name]: item.name,
        [OrganizationQueryKey.Status]: item.status,
        [OrganizationQueryKey.CreatedAt]: item.createdAt,
        [OrganizationQueryKey.ContactEmail]: item.contactEmail,
      } as DisplayedOrganizationsData;
    });
    return { items: _items, pagination };
  }, [listOrganizationsRes?.listOrganizations?.items, listOrganizationsRes?.listOrganizations?.pagination]);

  const columns: GridColDef<DisplayedOrganizationsData>[] = useMemo(
    () => [
      {
        width: 300,
        headerName: t('name.thing'),
        field: OrganizationQueryKey.Name,
        renderCell: ({ row, value }) => (
          <Link
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
            to={generatePathname(AppRouteEnum.OrganizationDetail, { id: row.id })}
          >
            {value}
          </Link>
        ),
      },
      {
        width: 300,
        headerName: t('contact_email'),
        field: OrganizationQueryKey.ContactEmail,
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: OrganizationQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
    ],
    [t]
  );

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.secondary">{t('list_organizations')}</Typography>
      </Breadcrumbs>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={t('list_organizations')}
          cardContent={
            <ListTable
              notSquare
              noBorder
              rows={items}
              columns={columns}
              tableName="list_organizations"
              searchLabel={t('name.thing')}
              isLoading={loadingListOrganizations}
              localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
              search={organizationQuery.searchText}
              onSearch={(v) => updateOrganizationQuery({ page: 1, searchText: v || '' })}
              paginationData={pagination}
              onPagination={updateOrganizationQuery}
              sort={{
                sortBy: organizationQuery.sortBy,
                orderBy: organizationQuery.orderBy,
              }}
              onSort={updateOrganizationQuery}
            />
          }
        />
      </Box>
    </UserLayout>
  );
};

export default ListOrganizations;
