import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import AddUserDialog from '~/components/add-member-dialog';
import UserLayout from '~/components/app-layout/user-layout';
import ChangeUserRole from '~/components/change-user-role';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import ListTable from '~/components/list-table';
import GridToolbarSearchByAPI from '~/components/list-table/GridToolbarSearchByAPI';
import WrapperWithFab from '~/components/WrapperWithFab';
import { ADMIN_ACTIONS } from '~/config/roleConfig';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { AdminRole, ListAdminDocument, useListAdminQuery, useRemoveAdminMutation } from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTable: {
    '.image-list-thumbnail': {
      height: '64px',
      width: '64px',
      borderRadius: '4px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const rolesList = (t: TFunction) => ({
  [AdminRole.Owner]: {
    label: t('role_options.owner'),
    value: AdminRole.Owner,
  },
  [AdminRole.Administrator]: {
    label: t('role_options.administrator'),
    value: AdminRole.Administrator,
  },
  [AdminRole.Manager]: {
    label: t('role_options.manager'),
    value: AdminRole.Manager,
  },
  [AdminRole.Operator]: {
    label: t('role_options.operator'),
    value: AdminRole.Operator,
  },
});

export enum AdminQueryKey {
  Uid = 'UID',
  Uuid = 'UUID',
  Role = 'ROLE',
  Email = 'EMAIL',
  CreatedAt = 'CREATED_AT',
  DisplayName = 'DISPLAY_NAME',
}

export interface IAdminList {
  id: string;
  [AdminQueryKey.Email]: string;
  [AdminQueryKey.Role]: AdminRole;
  [AdminQueryKey.CreatedAt]: Date;
  [AdminQueryKey.DisplayName]: string;
}

const initQuery = {
  page: 1,
  limit: ITEMS_PER_PAGE,
  searchText: '',
  sortBy: verifySortKey(AdminQueryKey, getLocalStorage('admin_list_sort')),
  orderBy: verifyOrderKey(getLocalStorage('admin_list_order')),
};

export const additionalAdministrator = (currentUserIsAdministrator: boolean, initRoles: AdminRole[]) =>
  currentUserIsAdministrator ? [AdminRole.Administrator].concat(initRoles) : initRoles;

const AdminManagement: React.FC<Props> = () => {
  const { classes } = useStyles();
  const { account } = useAccount();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const [adminQuery, setAdminQuery] = useState(initQuery);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState<IAdminList>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState(false);
  const [openChangeUserRoleDialog, setOpenChangeUserRoleDialog] = useState(false);

  const isOperator = account?.role === AdminRole.Operator;

  useEffect(() => {
    document.title = t('admin_management');
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      admin_list_order: adminQuery?.orderBy,
      admin_list_sort: adminQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminQuery?.orderBy, adminQuery?.sortBy]);

  const updateShopQuery = (newValue: any) => setAdminQuery((value: any) => ({ ...value, ...newValue }));

  const [removeAdmin] = useRemoveAdminMutation({
    refetchQueries: [ListAdminDocument],
  });
  const { data: listAdminsRes, loading: loadingListUsers } = useListAdminQuery({
    fetchPolicy: 'cache-and-network',
  });

  const items = useMemo(() => {
    const items = listAdminsRes?.listAdmin || [];

    const _items = items
      .map((item) => {
        return {
          id: item.uuid,
          [AdminQueryKey.Role]: item.role,
          [AdminQueryKey.Email]: item.email,
          [AdminQueryKey.CreatedAt]: item.createdAt,
          [AdminQueryKey.DisplayName]: item.displayName,
        };
      })
      .filter((i) => (i[AdminQueryKey.DisplayName] || '').toLowerCase().includes((debounceValue || '').toLowerCase()));
    return _items;
  }, [listAdminsRes?.listAdmin, debounceValue]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenRemoveMemberDialog = () => {
    handleCloseMenu();
    setOpenRemoveMemberDialog(true);
  };

  const onCloseRemoveMemberDialog = useCallback(async () => {
    setOpenRemoveMemberDialog(false);
  }, []);

  const handleOpenChangeMemberRoleDialog = () => {
    handleCloseMenu();
    setOpenChangeUserRoleDialog(true);
  };

  const handleCloseChangeMemberRoleDialog = useCallback(async () => {
    setOpenChangeUserRoleDialog(false);
  }, []);

  const onOpenAddUserDialog = useCallback(() => {
    setOpenAddUserDialog(true);
  }, []);

  const onCloseAddUserDialog = useCallback(() => {
    setOpenAddUserDialog(false);
  }, []);

  const handleConfirm = async () => {
    try {
      await removeAdmin({
        variables: {
          uuid: selectedMember?.id ?? '',
        },
      });
      enqueueSnackbar(t('toast_message.removed_member'), {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const checkDisabled = useCallback(
    (user: IAdminList) => {
      const accountNotExist = !account;
      const isCurrentUser = user[AdminQueryKey.Email] === account?.email;
      const isAdministrator = user[AdminQueryKey.Role] === AdminRole.Administrator;
      if ((isCurrentUser && !isAdministrator) || accountNotExist) return true;

      const editableRoles = additionalAdministrator(
        isAdministrator && isCurrentUser,
        ADMIN_ACTIONS.EDIT[account?.role]
      );

      // Check if the current role has permissions for this user
      return (
        !editableRoles?.includes(user[AdminQueryKey.Role]) &&
        !ADMIN_ACTIONS.DELETE[account?.role]?.includes(user[AdminQueryKey.Role])
      );
    },
    [account]
  );

  const handleClickMoreMenu = useCallback(
    (row: IAdminList): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedMember(row);
      },
    []
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        width: 200,
        field: AdminQueryKey.DisplayName,
        headerName: t('name.person'),
      },
      {
        width: 300,
        field: AdminQueryKey.Email,
        headerName: t('email'),
      },
      {
        width: 120,
        field: AdminQueryKey.Role,
        headerName: t('role'),
        renderCell: (params) => (
          <Typography variant="body2">{rolesList(t)[params.row[AdminQueryKey.Role] as AdminRole]?.label}</Typography>
        ),
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: AdminQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 50,
        headerName: '',
        type: 'actions',
        field: t('actions'),
        disableReorder: true,
        renderCell: (params) => {
          return [
            <IconButton
              key="1"
              size="small"
              disabled={checkDisabled(params.row)}
              onClick={handleClickMoreMenu(params.row)}
            >
              <MoreVertIcon />
            </IconButton>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, checkDisabled]
  );

  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, [i18n.language]);

  const toolbarProps = {
    search,
    searchLabel: `${t('name.person')}...`,
    handleSearch,
  };

  const listEditableRoles =
    selectedMember && account
      ? additionalAdministrator(
          selectedMember?.[AdminQueryKey.Email] === account?.email && account?.role === AdminRole.Administrator,
          ADMIN_ACTIONS.EDIT[account.role]
        )
      : [];

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.primary">{t('admin_management')}</Typography>
      </Breadcrumbs>
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {selectedMember && account && ADMIN_ACTIONS.DELETE[account.role]?.includes(selectedMember[AdminQueryKey.Role]) && (
          <MenuItem onClick={handleOpenRemoveMemberDialog} data-testid="DeleteMenu">
            {t('delete')}
          </MenuItem>
        )}
        {selectedMember && listEditableRoles?.includes(selectedMember[AdminQueryKey.Role]) && (
          <MenuItem onClick={handleOpenChangeMemberRoleDialog} data-testid="ChangeRoleMenu">
            {t('change_role')}
          </MenuItem>
        )}
      </Menu>
      <Box className={classes.wrapper}>
        <WrapperWithFab hideFab={isOperator} onClick={onOpenAddUserDialog}>
          <CustomCardTable
            cardTitle={t('admin_management')}
            headerAction={
              !isOperator && (
                <HeaderAction
                  menus={[
                    {
                      title: t('add_member'),
                      onClick: onOpenAddUserDialog,
                    },
                  ]}
                />
              )
            }
            cardContent={
              <Box className={classes.wrapperTable}>
                <ListTable
                  noBorder
                  rows={items}
                  columns={columns}
                  sortingMode="client"
                  pageSizeOptions={[ITEMS_PER_PAGE]}
                  rowCount={items.length}
                  paginationMode="client"
                  tableName="adminManagement"
                  paginationModel={undefined}
                  isLoading={loadingListUsers}
                  sort={{
                    sortBy: adminQuery.sortBy,
                    orderBy: adminQuery.orderBy,
                  }}
                  slots={{
                    toolbar: GridToolbarSearchByAPI,
                  }}
                  slotProps={{
                    toolbar: toolbarProps,
                  }}
                  initialState={{
                    pagination: { paginationModel: { pageSize: ITEMS_PER_PAGE } },
                  }}
                  onSort={updateShopQuery}
                />
              </Box>
            }
          />
        </WrapperWithFab>
        <AddUserDialog open={openAddUserDialog} onClose={onCloseAddUserDialog} />
        <ChangeUserRole
          memberInfo={selectedMember}
          open={openChangeUserRoleDialog}
          onClose={handleCloseChangeMemberRoleDialog}
        />
        <ConfirmationDialog
          open={openRemoveMemberDialog}
          title={t('delete_member')}
          onConfirm={handleConfirm}
          onClose={onCloseRemoveMemberDialog}
        />
      </Box>
    </UserLayout>
  );
};

export default AdminManagement;
