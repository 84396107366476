import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import { GridColDef, jaJP } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { env } from '~/env';
import { MyShopQueryKey, QueryOperator, useListOrganizationShopsQuery } from '~/graphql/admin/types';
import { generatePathname, getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export interface DisplayedOrganizationShopsData {
  id: string;
  [MyShopQueryKey.CreatedAt]: Date;
  [MyShopQueryKey.Publish]: boolean;
  [MyShopQueryKey.Title]: string | null | undefined;
}

const getInitQuery = (uuid: string = '', type: 'shop' | 'member') => ({
  page: 1,
  searchText: '',
  limit: ITEMS_PER_PAGE,
  organizationUuid: uuid,
  orderBy: verifyOrderKey(getLocalStorage(`organization_${type}_order`)),
  sortBy: verifySortKey(MyShopQueryKey, getLocalStorage(`organization_${type}_sort`)),
  where: {
    fields: [
      {
        value: [type],
        key: MyShopQueryKey.ShopType,
        operator: QueryOperator.Equals,
      },
    ],
  },
});

export const shopStatus = (t: TFunction<'translation', undefined>) => ({
  OPEN: {
    label: t('open'),
    color: 'success',
  },
  CLOSE: {
    label: t('close'),
    color: 'error',
  },
});

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTopTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 8px 8px',
  },
  shopURL: {
    color: '#000000DE',
    textDecoration: 'none',
    '&:hover': {
      color: '#1976d2',
      textDecoration: 'underline',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

interface IListShops {
  type: 'shop' | 'member';
}

const ListShops: FC<IListShops> = ({ type }) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { id: orgId } = useParams();
  const { t, i18n } = useTranslation();

  const isShop = type === 'shop';

  useEffect(() => {
    document.title = t('list_organizations');
  }, [t]);

  const [organizationShopsQuery, setOrganizationShopsQuery] = useState(getInitQuery(orgId, type));

  useEffect(() => {
    setLocalStorageItems({
      organization_shops_order: organizationShopsQuery?.orderBy,
      organization_shops_sort: organizationShopsQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationShopsQuery?.orderBy, organizationShopsQuery?.sortBy]);

  const updateOrganizationShopsQuery = (newValue: any) =>
    setOrganizationShopsQuery((value: any) => ({ ...value, ...newValue }));

  const { data: listOrganizationShopsRes, loading: loadingListOrganizationShops } = useListOrganizationShopsQuery({
    fetchPolicy: 'cache-and-network',
    variables: organizationShopsQuery,
  });
  const listOrganizationShops = listOrganizationShopsRes?.listOrganizationShops;

  const { items, pagination } = useMemo(() => {
    if (!listOrganizationShops) {
      return { items: [], pagination: {} };
    }
    const items = listOrganizationShops.items;
    const pagination: ListTablePagination = listOrganizationShops.pagination;

    const _items: DisplayedOrganizationShopsData[] = items.map(
      (item) =>
        ({
          id: item.uuid,
          category: item.siteSetting?.category,
          [MyShopQueryKey.Publish]: item.publish,
          description: item.siteSetting?.description,
          [MyShopQueryKey.CreatedAt]: item.createdAt,
          [MyShopQueryKey.Title]: item.siteSetting?.title,
          domain:
            env.REACT_APP_CLIENT_URL +
            (isShop
              ? generatePathname(AppRouteEnum.BuyerShop, { shopName: item.domain?.name || '' })
              : generatePathname(AppRouteEnum.BuyerMemberSite, { shopName: item.domain?.name || '' })),
          url: item.siteSetting?.ogp
            ? env.REACT_APP_API_MEDIA + '/' + item.siteSetting?.ogp
            : isShop
            ? '/default/ogp-shop.png'
            : '/default/ogp-member-site.png',
        } as DisplayedOrganizationShopsData)
    );
    return { items: _items, pagination };
  }, [isShop, listOrganizationShops]);

  const redirectToDetail = useCallback(
    (shopId: string) => {
      if (!orgId || !shopId) {
        return;
      }
      navigate(
        isShop
          ? generatePathname(AppRouteEnum.ShopOrganizationDetail, { id: orgId, shopId })
          : generatePathname(AppRouteEnum.MemberSiteOrganizationDetail, { id: orgId, shopId })
      );
    },
    [isShop, orgId, navigate]
  );

  const columns: GridColDef<DisplayedOrganizationShopsData>[] = useMemo(
    () => [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value || '/default/ogp-shop.png'} onClick={() => redirectToDetail(row.id)} />
            </Box>
          );
        },
      },
      {
        width: 300,
        field: MyShopQueryKey.Title,
        headerName: t(isShop ? 'shop_name' : 'site_name'),
      },
      {
        width: 84,
        headerName: t('status'),
        field: MyShopQueryKey.Publish,
        renderCell: ({ row }) => (
          <Chip
            label={row[MyShopQueryKey.Publish] ? t('open') : t('close')}
            color={row[MyShopQueryKey.Publish] ? 'success' : 'error'}
          />
        ),
      },
      {
        width: 500,
        field: 'domain',
        sortable: false,
        headerName: t('url'),
        renderCell: ({ value }) => {
          return (
            <Link target="_blank" className={classes.shopURL} href={value}>
              {value}
            </Link>
          );
        },
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: MyShopQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
    ],
    [t, isShop, classes.shopURL, redirectToDetail]
  );

  return (
    <CustomCardTable
      cardTitle={t(isShop ? 'shops' : 'member_sites')}
      cardContent={
        <ListTable
          notSquare
          noBorder
          rows={items}
          columns={columns}
          tableName={`list_${type}s`}
          searchLabel={t('name.thing')}
          isLoading={loadingListOrganizationShops}
          localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
          search={organizationShopsQuery.searchText}
          onSearch={(v) => updateOrganizationShopsQuery({ page: 1, searchText: v || '' })}
          paginationData={pagination}
          onPagination={updateOrganizationShopsQuery}
          sort={{
            sortBy: organizationShopsQuery.sortBy,
            orderBy: organizationShopsQuery.orderBy,
          }}
          onSort={updateOrganizationShopsQuery}
        />
      }
    />
  );
};

export default ListShops;
