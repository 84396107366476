export enum AppRouteEnum {
  Dashboard = '/',
  NotFound = '/404',
  SignIn = '/sign-in',
  Settings = '/settings',
  BuyerShop = '/shop/:shopName',
  AdminManagement = '/admin-management',
  BuyerMemberSite = '/member/:shopName',
  SupportedNetworks = '/supported-networks',
  ListOrganizations = '/list-organizations',
  OrganizationDetail = '/list-organizations/:id',
  ShopOrganizationDetail = '/list-organizations/:id/shop/:shopId',
  CollectionOrganizationDetail = '/list-organizations/:id/collection/:shopId',
  MemberSiteOrganizationDetail = '/list-organizations/:id/member-site/:shopId',
}
