import { SyntheticEvent, useEffect, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import PlansManagement from './plans-management';
import Points from './points';
import RevenueShare from './revenue-share';

import UserLayout from '~/components/app-layout/user-layout';
import HomeBtn from '~/components/home-btn';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()(() => ({}));

interface Props extends StyledComponentProps<typeof useStyles> {}

interface TabPanelProps {
  children: JSX.Element;
  value: number;
  index: number;
  style?: React.CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const initSettingTabsList = (t: TFunction) => {
  return [
    {
      label: t('settings.plans_management.title'),
      component: <PlansManagement />,
    },
    {
      label: t('settings.revenue_share.title'),
      component: <RevenueShare />,
    },
    {
      label: t('settings.points.title'),
      component: <Points />,
    },
  ];
};

const SettingPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const settingTabsList = initSettingTabsList(t);

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`);
  };

  useEffect(() => {
    document.title = t('settings.title');
  }, [t]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      const newTab = Number(tabFromUrl);
      const lengthSettingTabsList = settingTabsList?.length - 1;
      if (lengthSettingTabsList >= newTab) {
        setValue(newTab);
      } else {
        navigate(`${location.pathname}?tab=0`);
      }
    }
  }, [location.search, location.pathname, settingTabsList?.length, navigate]);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.primary">{t('settings.title')}</Typography>
      </Breadcrumbs>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          {settingTabsList.map((tab, idx) => (
            <Tab key={idx} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {settingTabsList.map((tab, idx) => (
        <TabPanel key={idx} style={{ width: '100%' }} value={value} index={idx}>
          {tab.component}
        </TabPanel>
      ))}
    </UserLayout>
  );
};

export default SettingPage;
