import React, { useMemo } from 'react';

import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import NavigatorMenu from '~/components/navigator-menu';
import { NavigatorItemConfig } from '~/components/navigator-menu/navigator-item';
import { DRAWER_WIDTH, DRAWER_WIDTH_SM } from '~/constants/layout';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    padding: theme.spacing(2),
    width: DRAWER_WIDTH_SM,
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
  },
  logo: {
    '& img': {
      width: 130,
      [theme.breakpoints.up('sm')]: {
        width: 170,
      },
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  isTemporarySideBar: boolean;
  openDrawer: boolean;
  onCloseDrawer: () => void;
}

const SideBar: React.FC<Props> = (props) => {
  const { isTemporarySideBar, openDrawer, onCloseDrawer } = props;
  const { t } = useTranslation();
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  const config = useMemo<NavigatorItemConfig[]>(
    () => [
      {
        path: AppRouteEnum.Dashboard,
        label: t('dashboard.title'),
        icon: <DashboardOutlinedIcon />,
      },
      {
        path: AppRouteEnum.ListOrganizations,
        label: t('list_organizations'),
        icon: <CorporateFareIcon />,
      },
      {
        path: AppRouteEnum.AdminManagement,
        label: t('admin_management'),
        icon: <ManageAccountsOutlinedIcon />,
      },
      {
        path: AppRouteEnum.SupportedNetworks,
        label: t('supported_networks'),
        icon: <ShareOutlinedIcon />,
      },
      {
        path: AppRouteEnum.Settings,
        label: t('settings.title'),
        icon: <SettingsOutlinedIcon />,
      },
    ],
    [t]
  );

  return (
    <Drawer
      variant={isTemporarySideBar ? 'temporary' : 'persistent'}
      open={isTemporarySideBar ? openDrawer : true}
      onClose={onCloseDrawer}
      classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      data-testid="side-bar"
      anchor={isTemporarySideBar ? 'right' : 'left'}
    >
      <NavigatorMenu config={config} isTemporarySideBar={isTemporarySideBar} onCloseDrawer={onCloseDrawer} />
    </Drawer>
  );
};

export default SideBar;
