import { ChangeEvent, FC, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

export interface MenuItemProps {
  menuTitle: string;
  handleMenuFunction: () => void;
}

export interface GridToolbarProps {
  search?: string;
  disabled?: boolean;
  searchLabel?: string;
  menuItems?: MenuItemProps[];
  handleSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles()((theme) => ({
  wrapperToolbar: {
    padding: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '.viewMode': {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '16px',
        flexFlow: 'wrap-reverse',
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap-reverse',
      '.viewMode': {
        alignContent: 'center',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      },
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    letterSpacing: '0.46px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '16px',
    },
  },
  searchAndFilter: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiInputBase-root.MuiInput-root': {
      lineHeight: '36.5px',
      '& .MuiInputBase-input': {
        height: '36.5px',
        boxSizing: 'border-box',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  filter: {
    display: 'flex',
  },
  search: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '16px',
    },
  },
}));

const GridToolbarSearchByAPI: FC<GridToolbarProps> = (props) => {
  const { search, disabled, menuItems, searchLabel, handleSearch } = props;

  const { t } = useTranslation();
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer className={classes.wrapperToolbar}>
      <Box className={classes.wrapper}>
        <Box className={classes.searchAndFilter}>
          <Box>
            <GridToolbarColumnsButton sx={{ padding: '0 5px' }} />
          </Box>
          <TextField
            variant="standard"
            id="standard-basic"
            disabled={disabled}
            value={search ?? ''}
            placeholder={searchLabel || t('search')}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className={classes.search}>
          {!!menuItems?.length && (
            <>
              <IconButton onClick={handleClickMenu}>
                <MoreVertIcon fontSize="inherit" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {menuItems?.map((item: MenuItemProps, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setAnchorEl(null);
                        item?.handleMenuFunction();
                      }}
                    >
                      {item.menuTitle}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export default GridToolbarSearchByAPI;
