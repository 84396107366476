import { useEffect } from 'react';

import { RouteObject, useLocation, useRoutes } from 'react-router-dom';

import PrivateWrapper from '~/contexts/PrivateWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import AboutPage from '~/pages/about';
import AdminManagement from '~/pages/admin-management';
import Dashboard from '~/pages/dashboard';
import ErrorPage from '~/pages/error';
import ListOrganizations from '~/pages/list-organizations';
import OrganizationDetail from '~/pages/list-organizations/organization-detail';
import ListMyShopCollections from '~/pages/list-organizations/organization-detail/details/ListMyShopCollections';
import OrganizationWrapper from '~/pages/list-organizations/organization-detail/OrganizationWrapper';
import SettingPage from '~/pages/settings';
import SignInPage from '~/pages/sign-in';
import SupportedNetworks from '~/pages/supported-networks';

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.SignIn,
    element: (
      <WithFirebaseUser>
        <SignInPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.Dashboard,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: AppRouteEnum.ListOrganizations,
        children: [
          {
            index: true,
            element: <ListOrganizations />,
          },
          {
            path: AppRouteEnum.OrganizationDetail.replace(/\/list-organizations\//g, ''),
            element: <OrganizationWrapper />,
            children: [
              {
                index: true,
                element: <OrganizationDetail />,
              },
              {
                path: AppRouteEnum.ShopOrganizationDetail.replace(/\/list-organizations\/:id\//g, ''),
                element: <ListMyShopCollections />,
              },
              {
                path: AppRouteEnum.MemberSiteOrganizationDetail.replace(/\/list-organizations\/:id\//g, ''),
                element: <ListMyShopCollections />,
              },
            ],
          },
        ],
      },
      {
        path: AppRouteEnum.AdminManagement,
        element: <AdminManagement />,
      },
      {
        path: AppRouteEnum.SupportedNetworks,
        element: <SupportedNetworks />,
      },
      {
        path: AppRouteEnum.Settings,
        element: <SettingPage />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage code="404" />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
